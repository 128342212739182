import React from 'react'
import Layout from '../../components/Layout';

const Anthro = () => {
    return(
        <Layout>
            Hello!
        </Layout>
    )
}

export default Anthro;